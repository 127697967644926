@font-face {
  font-family: 'Balto';
  src: url('../fonts/Balto-Bold.eot') format('embedded-opentype'), url('../fonts/Balto-Bold.woff') format('woff'), url('../fonts/Balto-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Balto';
  src: url('../fonts/Balto-Medium.eot') format('embedded-opentype'), url('../fonts/Balto-Medium.woff') format('woff'), url('../fonts/Balto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Balto';
  src: url('../fonts/Balto-Book.eot') format('embedded-opentype'), url('../fonts/Balto-Book.woff') format('woff'), url('../fonts/Balto-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

a {
  transition: color .25s;
}
