// Config

$enable-negative-margins: true !default;


// Sizes

$sizes: (
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%
) !default;


// Grid system

$grid-gutter-width: 1.8rem !default;


// Color system

$min-contrast-ratio: 2 !default;

$purple:    #6672e8 !default;
$primary:   #2083fe !default;
$secondary: #5A6C90 !default;
$success:   #5bac58 !default;
$info:      #18b7ff !default;
$warning:   #fdc724 !default;
$danger:    #ff7979 !default;
$light:     #FBFBFB !default;
$dark:      #1e2e50 !default;
$darkblue:  #263588 !default; // new
$bluemeta:  #480abf !default; // new
$sky:       #f6f9fc !default; // new


// Typography

$font-family-base:    'Balto', sans-serif !default;
$line-height-sm:      1.03 !default;
$line-height-base:    1.5 !default;
$line-height-lg:      1.6 !default;
$display-font-sizes: (
  1: 5.5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.4rem,
  5: 3rem,
  6: 2.45rem
) !default;

$lead-font-size:        1.1rem !default;
$font-size-sm:          .85rem !default;
$font-size-xs:          .7rem !default; // new
$text-muted:            #637396 !default;
$font-weight-bold:      500 !default;
$font-weight-bolder:    700 !default;
$headings-font-weight:  500 !default;
$hr-color:              #dee2e6 !default;
$hr-opacity:            1 !default;

$h1-font-size:          2.2rem !default;
$h5-font-size:          1.3rem !default;


// Links

$link-decoration:       none !default;
$link-hover-decoration: underline !default;


// Shadows

$box-shadow-sm:           0 .125rem .25rem rgba(35,38,45, 0.09) !default;
$box-shadow:              0 .5rem 1.25rem rgba(35,38,45, 0.125) !default;
$box-shadow-lg:           0 1rem 3rem rgba(35,38,45, .275) !default;
$box-shadow-light-sm:     0 .125rem .25rem rgba(35,38,45, 0.05) !default; // new
$box-shadow-light:        0 .5rem 1.25rem rgba(35,38,45, 0.05) !default; // new
$box-shadow-light-lg:     0 1rem 3rem rgba(35,38,45, .05) !default; // new
$box-shadow-success-lg:   0 3px 45px 20px rgba(98, 177, 72, 0.7) !default; // new


// Navbar

$navbar-padding-y:                  1.5rem !default;
$navbar-toggler-padding-x:          0 !default;
$navbar-nav-link-margin-x:          .7rem !default; // new
$navbar-nav-link-padding-x:         1.1rem !default;
$navbar-dropdown-box-shadow-lg:     $box-shadow-light !default; // new

$navbar-light-color:                $dark !default;
$navbar-light-hover-color:          $dark !default;
$navbar-light-active-color:         $dark !default;
$navbar-light-toggler-border-color: transparent !default;
$navbar-light-brand-color:          $dark !default;
$navbar-light-brand-hover-color:    $darkblue !default;


$navbar-dark-color:                 #fff !default;
$navbar-dark-hover-color:           #e2e5e8 !default;
$navbar-dark-toggler-border-color:  transparent !default;


// Footer

$footer-padding-y:                4.25rem !default; // new
$footer-header-font-size:         .95rem !default; // new
$footer-link-font-size:           .95rem !default; // new
$footer-text-font-size:           .8rem !default; // new

$footer-light-header-color:       $dark !default; // new
$footer-light-link-color:         $darkblue !default; // new
$footer-light-social-icon-bg:     $darkblue !default; // new
$footer-light-social-icon-color:  #fff !default; // new
$footer-light-text-color:         rgba($dark, .7) !default; // new
$footer-light-brand-color:        $dark !default; // new

$footer-dark-header-color:        $info !default; // new
$footer-dark-link-color:          $light !default; // new
$footer-dark-social-icon-bg:      $light !default; // new
$footer-dark-social-icon-color:   $dark !default; // new
$footer-dark-text-color:          rgba($light, .6) !default; // new
$footer-dark-brand-color:         $light !default; // new


// Alerts

$alert-bg-scale:    7% !default;
$alert-color-scale: 0% !default;


// Dropdowns

$dropdown-font-size:          .95rem !default;
$dropdown-border-color:       #fbfbfb !default;
$dropdown-link-color:         #495057 !default;
$dropdown-link-hover-color:   #454e9e !default;
$dropdown-link-active-color:  #454e9e !default;
$dropdown-link-hover-bg:      none !default;
$dropdown-link-active-bg:     none !default;
$dropdown-padding-y:          1rem !default;
$dropdown-header-padding:     0 1rem .5rem 1rem !default;
$dropdown-header-color:       #222 !default;
$dropdown-item-padding-y:     .35rem !default;
$dropdown-item-padding-x:     1.25rem !default;
$caret-spacing:               .5rem !default;


// Buttons

$btn-hover-bg-shade-amount:     10% !default;
$btn-hover-border-shade-amount: 15% !default;
$btn-padding-y:                 .4rem !default;
$btn-padding-x:                 1.1rem !default;
$btn-padding-y-sm:              .4rem !default;
$btn-padding-x-sm:              .6rem !default;
$btn-padding-y-lg:              1.1rem !default;
$btn-padding-x-lg:              2.1rem !default;
$btn-font-size-lg:              1.35rem !default;


// Badges

$badge-padding-y:       .25rem !default;
$badge-padding-x:       .75rem !default;
$badge-font-weight:     400 !default;
$badge-border-radius:   .35rem !default;
$badge-padding-y-lg:    .6rem !default; // new
$badge-padding-x-lg:    1rem !default; // new
$badge-font-size-lg:    .85rem !default; // new


// Cards

$card-spacer-y:     1.75rem !default;
$card-spacer-x:     1.75rem !default;
$card-border-width: 0 !default;
$card-border-color: rgba($dark, .09) !default;
$card-cap-bg:       transparent !default;


// List groups

$list-group-border-color: rgba($dark, .09) !default;
$list-group-item-padding-y: 1rem !default;
$list-group-bg:             transparent !default;


// Forms

$input-placeholder-color:   rgba(#000, .3) !default;
$input-border-color:        #e9ecef !default;
$input-focus-box-shadow:    none !default;
$input-focus-border-color:  $primary !default;

$input-group-addon-bg:      #fff !default;
$input-group-addon-color:   $darkblue !default;

$form-check-input-width:            1rem !default;
$form-check-padding-start:          $form-check-input-width + .5rem !default;
$form-check-input-bg:               #dee2e6 !default;
$form-check-input-border:           none !default;
$form-check-input-focus-box-shadow: none !default;
$form-check-input-active-filter:    brightness(95%) !default;

$form-switch-color:         #fff !default;
$form-switch-width:         3em !default;
$form-switch-focus-color:   #fff !default;


// Pagination

$pagination-color:        $dark !default;
$pagination-bg:           transparent !default;
$pagination-border-width: 0 !default;
$pagination-hover-color:  $dark !default;
$pagination-hover-bg:     #f8f9fa !default;
$pagination-active-bg:    $dark !default;
$pagination-active-color: $light !default;
$pagination-padding-x:    .85rem !default;


// Breadcrumbs

$breadcrumb-bg:           none !default;
$breadcrumb-padding-x:    0 !default;
$breadcrumb-active-color: $darkblue !default;


// Modals

$modal-backdrop-opacity:  0.85 !default;
$modal-backdrop-bg:       $dark !default;
$modal-content-bg:        #f4f5fb !default;
$modal-header-padding-y:  1.5rem !default;
$modal-header-padding-x:  1.75rem !default;
$modal-inner-padding:     1rem 1.75rem !default;
$modal-md:                550px !default;


// Tables

$table-cell-padding-y:    1rem !default;
$table-cell-padding-x:    1rem !default;
$table-border-color:      #e9ecef !default;
$table-hover-bg:          darken($light, 2%) !default;


// Transitions

$bg-transition: background-color .2s linear !default; // new


// Navs

$nav-pills-link-active-color: $dark !default;
$nav-pills-link-active-bg:    #fff !default;


// Close

$btn-close-width: .7em !default;


// Popovers

$popover-header-bg:       #fff !default;
$popover-max-width:       350px !default;
$popover-font-size:       1.1rem !default;
$popover-body-padding-y:  .75rem !default;
$popover-body-padding-x:  1.5rem !default;
$popover-body-color:      $success !default;
$popover-border-color:    rgba(#000, .1) !default;
$popover-border-radius:   30px !default;


// Z-index

$zindex-navbar:           1034 !default; // new
