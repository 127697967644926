// Add/remove values from sass maps
$theme-colors: map-merge(
  $theme-colors,
  (
    "purple": $purple,
    "darkblue": $darkblue
  )
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: $theme-colors-rgb;
$utilities-text: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-color)
  )
);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");
$utilities-bg: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg)
  )
);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");

$spacers: map-merge(
  $spacers,
  (
    6: ($spacer * 4),
    7: ($spacer * 6),
    8: ($spacer * 8)
  )
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);


// Override utilities:
$utilities: () !default;
$utilities: map-merge(
  (
    "background-gray": (
      property: background,
      class: bg-gray,
      values: $grays
    ),
    "color-gray": (
      property: color,
      class: text-gray,
      values: $grays
    ),
    "border-gray": (
      property: border-color,
      class: border-gray,
      values: $grays
    ),
    "width": (
      property: width,
      class: w,
      responsive: true,
      values: map-merge($sizes, (auto: auto))
    ),
    "height": (
      property: height,
      class: h,
      responsive: true,
      values: map-merge($sizes, (auto: auto))
    ),
    "opacity": (
      property: opacity,
      values: (0: 0, 10: .1, 20: .2, 30: .3, 40: .4, 50: .5, 60: .6, 70: .7, 80: .8, 90: .9, 100: 1)
    ),
    "position": (
      property: position,
      responsive: true,
      values: static relative absolute fixed sticky
    ),
    "shadow-light": (
      property: box-shadow,
      class: shadow-light,
      values: (
        null: $box-shadow-light,
        sm: $box-shadow-light-sm,
        lg: $box-shadow-light-lg
      )
    ),
    "shadow-success": (
      property: box-shadow,
      class: shadow-success,
      values: ( lg: $box-shadow-success-lg )
    ),
    "font-size": (
      property: font-size,
      class: fs,
      values: (
        xs: $font-size-xs,
        sm: $font-size-sm,
        normal: $font-size-base,
        lg: $font-size-lg
      )
    ),
    "letter-spacing": (
      property: letter-spacing,
      values: (
        sm: -3px,
        lg: 1px,
        xl: 5px
      )
    ),
    "z-index": (
      property: z-index,
      values: 11 2 1 0 -1 -2
    ),
    "flex": (
      property: flex,
      values: 1
    ),
    "fixed-widths": (
      property: width,
      class: w,
      responsive: true,
      values: (150: 150px, 200: 200px, 250: 250px, 300: 300px)
    ),
    "fixed-heights": (
      property: height,
      class: h,
      responsive: true,
      values: (150: 150px, 200: 200px, 250: 250px, 300: 300px)
    ),
    "viewport-width": (
      property: width,
      class: vw,
      responsive: true,
      values: (50: 50vw, 100: 100vw)
    )
  ),
  $utilities
);
