// 
// Theme switcher
// 

.theme-switcher {
  z-index: 9999;
  width: 50px;
  text-align: left;
  position: relative;
  margin: 60px auto 40px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    top: 4px;
    border-radius: 50px;
    border: 3px solid;
    z-index: -1;

    transition: border-color .15s linear;
  }

  &.theme-switcher-light {
    &:before { border-color: #d1d5da; }

    .icon-circle {
      background-color: #172056;
      color: #f7d700;
    }
  }

  &.theme-switcher-dark {
    &:before { border-color: #424cb3; }

    .icon-circle {
      background-color: #505bca;
      color: #fff;
      transform: translateX(19px);
    }
  }

  .icon-circle {
    cursor: pointer;
    width: 2rem;
    height: 2rem;

    transition: all .15s linear;
  }
}
