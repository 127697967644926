// 
// Form control showing just a border bottom
// 

.form-control-bottom {
  border: 0;
  border-radius: 0;
  padding: 0;
  border-bottom: $input-border-width solid $input-border-color;
}

.form-text-help { color: #a28a53 !important; }
