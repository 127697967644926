// 
// Relative top helpers
// 

@for $i from 1 through 10 {
  .relative-top-#{$i} {
    position: relative;
    top: #{$i}px;
  }

  .relative-top--#{$i} {
    position: relative;
    top: -#{$i}px;
  }
}

.bottom-md-initial {
  @include media-breakpoint-down(md) {
    bottom: 0;
  }
}
