// 
// Extra transform utilities
// 

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}
