.navbar {
  z-index: $zindex-navbar;

  @include media-breakpoint-down(sm) {
    padding-bottom: 1.1rem;
  }

  // hover effect
  .dropdown-menu {
    @include media-breakpoint-up(lg) {
      display: block;
      visibility: hidden;
      opacity: 0;
      z-index: 0;
      margin-top: 0;
      box-shadow: $navbar-dropdown-box-shadow-lg;
      transform: translateY(10px);
      @include transition(all .25s);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: none !important;
        z-index: $zindex-navbar;
      }
    }
  }
}

.navbar-nav {
  .nav-link {
    font-size: .95rem;
    font-weight: 500;
    margin-left: $navbar-nav-link-margin-x;
    margin-right: $navbar-nav-link-margin-x;
  }
}

.navbar-collapse {
  .svg-icon {
    svg:not([fill=none]),
    [fill]:not([fill=none]) {
      fill: currentColor!important;
    }
  }
}


// Add a bg color to navbars that have .bg-transparent for mobile devices

.navbar-light {
  &.bg-transparent {
    @include transition($bg-transition);

    &.navbar-toggled {
      background-color: $white !important;
    }
  }
}

.navbar-dark {
  &.bg-transparent {
    @include transition($bg-transition);

    &.navbar-toggled {
      background-color: $gray-900 !important;
    }
  }
}
