// 
// Large badge size
// 

.badge-lg {
  padding: $badge-padding-y-lg $badge-padding-x-lg;
  font-size: $badge-font-size-lg;
}

a.badge {
  text-decoration: none;

  @include transition(all .2s);

  &.bg-sky:hover {
    background-color: #d0e0ef !important;
  }
}
