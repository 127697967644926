.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-cover-top { background-position: center 0px; }
.bg-cover-bottom { background-position: center 100%; }

// Pastel theme colors

@each $color, $value in $theme-colors {
  .bg-pastel-#{$color} {
    background-color: tint-color($value, 90%) !important;
  }
}
