// 
// Pastel text and link colors
// 

@each $color, $value in $theme-colors {
  $pastel: tint-color($value, 60%);

  .text-pastel-#{$color} {
    color: $pastel !important;
  }

  .link-pastel-#{$color} {
    color: $pastel;

    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
      }
    }
  }
}


// 
// Extra text helpers
//

.font-georgia { font-family: Georgia, "Merriweather", Arial; }
.text-underline { text-decoration: underline; }
.text-uppercase { letter-spacing: .8px; }
.text-shadow { text-shadow: 0 1px 17px rgba(0,0,0,0.48); }
.font-smooth { -webkit-font-smoothing: antialiased; }
.text-fb { color: #4566A7; }

.text-highlight-danger {
  padding: .3rem .4rem;
  color: $white;
  background: linear-gradient(90deg,#ff8484 0,#ff8a8a 80%);
}

.text-highlight-success {
  padding: .3rem .4rem;
  color: $darkblue;
  background: linear-gradient(90deg, tint-color($success, 70%) 0%, tint-color($success, 80%) 100%);
}

.text-highlight-success-light {
  padding: .3rem .4rem;
  color: $success;
  background: linear-gradient(90deg, #e6f7e5 0, #deeede 100%);
}

.lead-lg {
  font-size: 1.2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}

.h5 {
  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}


// 
// Shortcut for a combination of styles that are commonly used throughout the theme
// in buttons, subtitles, etc to prevent repetition.
// 

.text-uppercase-bold-sm {
  text-transform: uppercase !important;
  font-weight: $font-weight-bold !important;
  letter-spacing: 2px !important;
  font-size: $font-size-sm !important;
}

.text-uppercase-xs {
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  font-size: $font-size-xs !important;
}
