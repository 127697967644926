// 
// SVG positioning to create a division between sections
// 

.svg-divider {
  position: absolute;
  left: 0;
  right: 0;
}

// Positioning

.svg-divider-top {
  top: -1px;
  transform: scaleX(-1) scaleY(-1);
}

.svg-divider-terrain {
  top: -40px;
  transform: scaleX(-1) scaleY(-1);

  @include media-breakpoint-down(sm) {
    top: -20px;
  }
}

.svg-divider-terrain-vertical {
  top: 0;
  bottom: 0;
  transform: scaleY(-1);
  left: -50px;
  right: inherit;
}

.svg-divider-bottom {
  bottom: -1px;
}
